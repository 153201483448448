.user-name {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.mobile-number {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #000000;
  padding-top: 15px;
}
.mobile-number-sub {
  padding-right: 10px;
  font-size: 20px;
  color: #000000;
  padding-top: 5px;
}
.profile-buttons {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  color: #2c5566;
  padding-top: 50px;
}

.personal-details {
  background: #fff;
  border-radius: 10px;
  padding-top: 15px;
  margin-left: 10px;
  box-shadow: 4px 4px 50px rgba(178, 205, 206, 0.5);
}
.profile-details {
  display: flow-root;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 15px;
  padding-top: 20px;
}
.user-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #b2cdcf;
  background-color: #2c5566;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.makeStyles-content-6 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.update-button {
  background: #2c5566 !important;
  border-radius: 8px;
  color: #fff !important;
  padding: 10px !important;
  font-weight: bold;
  padding-left: 35px !important;
  padding-right: 35px !important;
  cursor: pointer;
}

.sidenav {
  height: 100%;
  width: 13%;
  position: fixed;
  z-index: 1 !important;
  top: 0;
  left: 0;
  background-color: #111;
  padding-top: 0px;
}

.sidenav a {
  padding: 0px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.main {
  margin-left: 0px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.topnav {
  overflow: hidden;
  background-color: #e9e9e9;
}

.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #2196f3;
  color: white;
}

.topnav .search-container {
  float: right;
}

.topnav input[type="text"] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
}

.topnav .search-container button {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.topnav .search-container button:hover {
  background: #ccc;
}

@media screen and (max-width: 600px) {
  .topnav .search-container {
    float: none;
  }
  .topnav a,
  .topnav input[type="text"],
  .topnav .search-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .topnav input[type="text"] {
    border: 1px solid #ccc;
  }
}

.field-label {
  color: #92b7bc !important;
}

@media screen and (max-width: 1200px) {
  .field-label {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1000px) {
  .profile-label {
    font-size: 12px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .profile-card {
    padding: 20px !important;
  }
}

.fileContainer {
  background: transparent;
  box-shadow: 0px 0px !important;
  position: relative;
  border-radius: 10px;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto !important;
  transition: all 0.3s ease-in;
}

.fileContainer .chooseFileButton {
  padding: 6px 10px !important;
  font-size: 10px !important;
  margin: 8px 0 !important;
}

.logo-icon1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 12px 0px 0px 0px;
  text-align: center;
  font: 28px Arial, sans-serif;
  background: #92b7bc;
  color: #fff !important;
  margin-left: auto;
  margin-right: auto;
  font-weight: normal;
  font-style: normal;
}

.number {
  text-align: center;
  font: 16px Arial, sans-serif;
}

.layout-style {
  padding-left: 9%;
  width: 100%;
  position: absolute;
}

.header-style {
  margin-left: 2%;
  margin-top: 2%;
  margin-right: 2%;
}

.page-title {
  color: #2c5566;
  font-weight: normal;
}

.form-control-label {
  margin-top: 10px !important;
  margin-left: 15px !important;
}

.input-label {
  width: 100% !important;
  margin-bottom: 10px !important;
  background: #fff !important;
  color: #2c5566 !important;
}

.profile-card-style {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-right: 10px;
  box-shadow: 4px 4px 50px rgba(178, 205, 206, 0.5);
}

.personal-details-title {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  float: left;
}

.change-password-title {
  color: #2c5566;
  font-size: 14px;
  font-weight: bold;
  float: right;
  margin-right: 10px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 5px;
}

.divider-style {
  border-bottom: 1px solid lightgrey;
  padding-top: 2px;
  margin-left: 20px;
}

.personal-details-card {
  padding: 0px 30px 10px 0px;
}

.personal-details-card-outer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-account-button-style {
  float: right;
  margin-top: 15px;
  padding-bottom: 30px;
}

.my-account-button {
  padding: 10px !important;
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.personal-view-label {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1000px) {
  .personal-view-label {
    display: inline-block !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 11px;
  }
}

.personal-view-data {
  padding-right: 6px;
}

.edit-icon-style {
  color: #2c5566;
  cursor: pointer;
  margin-left: 15px;
  position: relative;
}

.mobile-style {
  color: #92b7bc;
  font-size: 15px;
}

.call-icon-style {
  color: #92b7bc;
  margin-right: 6px;
  margin-left: -34px;
  margin-top: -2px;
}

.profile-image {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid lightgray;
}

@media screen and (max-width: 1600px) {
  .profile-image {
    width: 112px !important;
    height: 112px !important;
  }
  #upload-image {
    margin-top: -30px !important;
  }
}

@media screen and (max-width: 1300px) {
  .profile-image {
    width: 100px !important;
    height: 100px !important;
  }
}

@media screen and (max-width: 1024px) {
  .profile-image {
    width: 80px !important;
    height: 80px !important;
  }
  #upload-image {
    margin-top: -25px !important;
    font-size: 12px !important;
    padding: 8px !important;
    border-radius: 30px !important;
  }
  .call-icon-style {
    margin-left: 0px;
  }
}

.account-background-image {
  position: fixed;
}

.my-account-background {
  background: transparent;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.my-account-background-outer {
  position: relative;
  margin: 30px;
  top: 80px;
}

.my-account-content {
  overflow-y: scroll;
}

.image-upload > input {
  display: none;
}

.image-upload img {
  width: 80px;
}

.profile-image:hover {
  opacity: 0.5;
}
#upload-image {
  color: #fff;
  float: right;
  margin-top: -30px;
  font-size: 14px;
  opacity: 0.9;
  background: #2d5566;
  padding: 12px;
  border-radius: 30px;
}

.ellipsis-style {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
