body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  font-family: Muli;
  font-style: normal;
}

.modal-content {
  font-family: Muli !important;
  font-style: normal;
  border-radius: 0px !important;
}

.modal-header {
  border-radius: 0px !important;
  color: #2c5566 !important;
}

.modal-footer {
  border-radius: 0px !important;
}

.sidebar-title {
  font-family: Muli !important;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.err {
  padding: 0px !important;
  font-size: 14px;
}
.no-data {
  text-align: center;
  font-size: 30px;
  padding: 40px;
  background: white;
}
.Toastify__toast--success {
  background: #2c5566 !important;
  text-align: center;
}

.Toastify__toast--warning {
  text-align: center;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  padding: 4px 10px;
  border: 1px solid lightgrey;
}

div.participant > video {
  width: 100%;
}

div.participant {
  border: 1px solid gray;
  display: inline-flex;
  height: 150px;
  margin: 10px 5px;
  max-width: 220px;
  overflow: hidden;
  margin-top: 20px;
}

div.participant > video {
  width: 100%;
  object-fit: cover;
}

div.participant.main {
  height: fit-content;
  margin: 0px 0;
  max-width: 100%;
  width: 100%;
}

div.participant.main > video {
  height: 640px;
  border-left: 1px solid;
}

div.participant.active {
  border: 1px solid crimson;
  box-shadow: 0 0 5px crimson;
}

div.participant.active.pinned {
  border: 1px solid limegreen;
  box-shadow: 0 0 5px limegreen;
}

div.participant:hover {
  cursor: pointer;
}

div.participant::before {
  background-color: black;
  color: white !important;
  content: attr(data-identity);
  font-size: 10px;
  /* position: absolute; */
  z-index: 1000;
}

div.participant.main::before {
  font-size: 14px;
}

@media (max-width: 576px) and (max-width: 992px) {
  div#participants {
    overflow-x: auto;
    white-space: nowrap;
    height: auto;
  }

  div.participant.main > video {
    height: 270px;
  }

  div.container-fluid {
    overflow-y: scroll;
  }
}

@media (max-width: 768px) {
  div.participant.main > video {
    height: 270px;
  }
  div#participants {
    overflow-x: auto;
    white-space: nowrap;
    height: auto;
  }
}

@media (max-width: 1600px) {
  div.participant.main > video {
    height: 420px;
    /* height: inherit; */
  }
}

@media (max-width: 1300px) {
  div.participant.main > video {
    height: 290px;
    /* height: inherit; */
  }
}

div.container-fluid {
  height: 100vh;
}

div#participants {
  overflow-y: auto;
}

.participant-name-design {
  position: relative;
  text-transform: uppercase;
  top: 125px;
  text-align: left;
  width: 220px;
  height: fit-content;
  padding-left: 10px;
  background: #f9fbfb;
  opacity: 0.8;
  font-family: Muli;
  font-weight: bold;
  color: #000000;
  line-height: 25px;
  font-size: 11px;
}

@media screen and (max-width: 1250px) {
  .participant-name-design {
    width: 180px !important;
  }
  div.participant {
    max-width: 180px;
  }
}

.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area {
  background: rgba(22, 22, 333, 0.2);
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}

.draggable-video {
  position: relative;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  background-size: cover;
  overflow: hidden;
}

.sweet-loading {
  margin: 0;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup {
  left: 0 !important;
}

.css-yk16xz-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: #2c5566 !important;
  border-radius: 0px !important;
  border-style: solid;
  border-width: 1px;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-1okebmr-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: #fff !important;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}

.css-tlfecz-indicatorContainer {
  color: #2c5566 !important;
}

.flexible-modal-mask {
  background: none !important;
}

.flexible-modal {
  z-index: 100 !important;
}

.close-icon {
  position: absolute;
  right: -12px;
  top: -12px;
  background: #ff1a33;
  font-size: 18px;
  border-radius: 90px;
  cursor: pointer;
}

.call-history-modal-header {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  color: #2c5566 !important;
  background: white;
  border-radius: 0px !important;
  display: inline-block !important;
  text-align: left;
  padding: 20px;
}

.MuiPaginationItem-page.Mui-selected:hover,
.MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
  background-color: #2c5566 !important;
  color: white;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: #2c5566 !important;
  color: white;
  border-radius: 0px;
}
