.card-design {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidenav {
  height: 100%;
  width: 13%;
  position: fixed;
  z-index: 1 !important;
  top: 0;
  left: 0;
  background-color: #111;
  padding-top: 0px;
}

.sidenav a {
  padding: 0px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.main {
  margin-left: 0px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.topnav {
  overflow: hidden;
  background-color: #e9e9e9;
}

.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #2196f3;
  color: white;
}

.topnav .search-container {
  float: right;
}

.topnav input[type="text"] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
}

.topnav .search-container button {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.topnav .search-container button:hover {
  background: #ccc;
}

@media screen and (max-width: 600px) {
  .topnav .search-container {
    float: none;
  }
  .topnav a,
  .topnav input[type="text"],
  .topnav .search-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .topnav input[type="text"] {
    border: 1px solid #ccc;
  }
}

.pagination-style {
  float: right;
  padding: 20px;
}

.layout-style {
  padding-left: 9%;
  width: 100%;
}

.header-style {
  margin-left: 3%;
  margin-top: 2%;
  margin-right: 2%;
}

.page-title {
  color: #2c5566;
  font-weight: normal;
}

.card-style {
  background: #fff !important;
  border-radius: 10px !important;
  box-shadow: 4px 4px 50px rgba(178, 205, 206, 0.5) !important;
  padding: 10px !important;
}

.request-image-style {
  width: 68px;
  height: 68px;
  border-radius: 90px;
  cursor: pointer;
  border: 1px solid lightgray;
}

.icon-color {
  color: #fff;
}

.request-button-style {
  text-align: center;
}

.accept-button {
  background: #2c5566 !important;
  margin: 5px !important;
  border-radius: 8px !important;
  color: #fff !important;
  font-size: 10px !important;
  box-shadow: 4px 4px 50px rgba(110, 144, 158, 0.5) !important;
  font-weight: normal;
  font-style: normal;
}

.reject-button {
  background: #92b7bc !important;
  margin: 5px !important;
  border-radius: 8px !important;
  color: #fff !important;
  font-size: 10px !important;
  box-shadow: 4px 4px 50px rgba(110, 144, 158, 0.5) !important;
  font-weight: normal;
  font-style: normal;
}

.request-name {
  color: #2c5566;
  font-weight: normal;
  font-style: normal;
  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 170px;
  display: inline-block;
  cursor: pointer;
}

@media screen and (max-width: 1600px) {
  .request-name {
    width: 150px;
  }
  .request-data {
    font-size: 12px;
  }
}

@media screen and (max-width: 1300px) {
  .request-name {
    width: 130px;
  }
  .request-data {
    font-size: 11px;
  }
}

.phone-icon {
  font-size: 16px;
  padding-right: 8px;
  color: #92b7bc;
}

.phone-number {
  color: #000000;
}

.card-content-style {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-content {
  margin-left: 5px;
}

.card-inner-content {
  padding: 16px;
}

.card-outer-content {
  padding: 10px;
}

@media screen and (max-width: 1440px) {
  .text-content {
    margin-left: 12px !important;
  }
}
