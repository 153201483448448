.toolbar {
  background-image: url("../assets/background-tile.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: inherit;
}
.searchArea {
  width: 70%;
  margin: 10px;
}
.searchField {
  width: 100%;
}
.iconArea {
  width: 30%;
  text-align: right;
  padding-left: 5px;
  padding-right: 5px;
}
.imageArea {
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.profileName {
  padding-left: 15px;
  cursor: pointer;
}
.logoutIcon {
  padding-left: 5px;
}
.logo {
  height: 7.9%;
  width: 85%;
  margin: 3px;
  margin-left: 15px;
}
.footer {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #2c5566;
  color: white;
  text-align: center;
  margin: 0px;
  padding-top: 8px;
}

.topnav {
  overflow: hidden;
  background-color: #e9e9e9;
}

.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #2196f3;
  color: white;
}

.topnav .search-container {
  float: right;
}

.topnav input[type="text"] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
}

.topnav .search-container button {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.topnav .search-container button:hover {
  background: #ccc;
}

@media screen and (max-width: 600px) {
  .topnav .search-container {
    float: none;
  }
  .topnav a,
  .topnav input[type="text"],
  .topnav .search-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .topnav input[type="text"] {
    border: 1px solid #ccc;
  }
}

.dropdown-menu {
  padding: 0.2rem 0 !important;
  min-width: 180px !important;
  border-radius: 0.25rem;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: rgba(178, 205, 206, 0.5) 4px 4px 50px;
}

.MuiBadge-colorSecondary {
  background-color: #2c5566 !important;
}

@media screen and (max-width: 992px) {
  .collapse {
    display: flex !important;
  }
}

.toolbar-header-view {
  margin-left: 3%;
  margin-right: 2%;
  margin-top: 15px;
  border-radius: 20px;
}

.toolbar-form-field {
  margin-left: 15px;
  width: 100%;
}

.toolbar-width {
  width: 75%;
}

.notification-icon {
  color: #2c5566;
}

.toolbar-button {
  background: linear-gradient(90deg, #2c5566 0%, #2c5566 0.01%, #073244 100%);
  width: 195px;
  border-radius: 25px;
  height: 65px;
}

.toolbar-image {
  width: 40px;
  height: 40px;
  border-radius: 90px;
  margin-right: 12px;
  cursor: pointer;
  box-shadow: 4px 4px 50px rgba(110, 144, 158, 0.5);
  border: 1px solid white;
  background: white;
}

.logout-button {
  color: #ffff;
}

.toolbar-search-input {
  width: "75%";
  background: "#FFF";
  height: "45px";
  border: "1px solid transparent";
  border-radius: "10px";
}

.toolbar-search-button {
  background: #fff;
  border: none;
}

.toolbar-search-icon {
  color: #2c5566;
}

.logout-dropdown {
  padding-right: 10px;
}

.toolbar-username {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80px;
  display: inline-block;
  overflow: hidden;
  margin-bottom: -5px;
  text-transform: capitalize;
}

.logoutPopupInner {
  position: absolute;
  left: 39%;
  right: 39%;
  top: 30%;
  margin: auto;
  background: white;
}

.logout-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.notification-popup {
  position: absolute;
  left: 30%;
  right: 30%;
  top: 10%;
  margin: auto;
  background: white;
}
