.form-control {
    color: black !important;
    background-color: #fff !important;
}
.update-button {
    background: #2c5566 !important;
    border-radius: 8px;
    color: #fff !important;
    padding: 10px !important;
    font-weight: bold;
    padding-left: 35px !important;
    padding-right: 35px !important;
    cursor: pointer;
}

.update-button-align {
    text-align: end;
    margin-top: 20px;
    margin-right: 50px;
}

@media screen and (max-width: 1200px) {
    .update-button-align {
        margin-right: 20px !important;
        text-align: end;
        margin-top: 20px;
        margin-right: 50px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1700px) {
    .update-button-align {
        margin-right: 30px !important;
        text-align: end;
        margin-top: 20px;
        margin-right: 50px;
    }
}

.form-field-style {
    margin-top: 10px;
    margin-left: 15px;
}
.input-field-style {
    width: 100%;
    margin-bottom: 15px;
}

.layout-style {
    padding-left: 9%;
    width: 100%;
}

.header-style {
    margin-left: 3%;
    margin-top: 2%;
    margin-right: 2%;
}

.page-title {
    color: #2c5566;
    font-weight: normal;
}

.page-content {
    width: 97%;
    margin-right: 15px;
    padding-left: 15px;
}

.page-top-content {
    margin-top: 20px;
}

.changepaswword-password-button {
    background-color: #ffff !important;
    border: 1px solid #b2cdcf;
}

.changepaswword-password-icon {
    color: black;
}
