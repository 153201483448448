.login-page {
  background-image: url("../assets/background-image.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  display: flex;
  align-items: center;
}
.login-view {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 86%;
  height: 90%;
}
.forgot-card {
  min-width: 27%;
  position: relative;
  bottom: 15px;
  text-align: -webkit-right;
  text-align: -moz-right;
}
.forgot-view {
  background: #2c5566;
  border-radius: 62px;
  padding: 50px 55px 25px 45px;
  display: block;
  position: relative;
  width: 100%;
}
.forgot-logo-view {
  display: flex;
  justify-content: center;
  padding: 15px;
  margin-right: 45px;
  width: 100%;
}
@media screen and (max-width: 800px) {
  .forgot-view {
    width: 80%;
  }
  .forgot-logo-view {
    width: 55%;
  }
}
.card-header {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}
.logo {
  width: auto;
  height: auto;
}
.logo-image {
  width: auto;
  height: auto;
}
.card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-size: 15px;
  background: transparent;
  border-bottom: 1px;
}

.card-title {
  float: right;
}
.MuiInputLabel-animated {
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #fff !important;
}
.MuiDivider-root {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background: #fff !important;
}
.MuiInputBase-input {
  font: inherit;
  color: #fff !important;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}
.button-view {
  display: flex;
  justify-content: center;
}
.forgot-button {
  background: #b2cdcf !important;
  border-radius: 8px;
  color: #fff !important;
  padding: 10px !important;
  font-weight: bold;
  padding-left: 25px !important;
  padding-right: 25px !important;
  cursor: pointer;
}
.signin-view {
  display: flex;
  justify-content: center;
}
.signin-link {
  cursor: pointer;
  color: #fff !important;
  font-weight: bold;
  text-decoration: none !important;
}
.signin-link:hover {
  text-decoration: underline !important;
}
.signin {
  color: #fff;
  font-size: 15px;
}
.err {
  color: red;
}
.form-label {
  font-size: 14px !important;
  color: #fff !important;
}
.form-view {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 25px;
}
.form-controls {
  margin-top: 10px;
  width: 100%;
}
.text-fields {
  width: 100%;
  border-bottom: 1px solid #fff !important;
  margin-bottom: 15px;
}
.field-icon {
  color: #fff;
}
.forgot-link-view {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: auto;
  margin-right: 10px;
  text-align: end;
}
.forgot-link {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 2px;
}
