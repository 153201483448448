.footer {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 40px;
  color: #2c5566;
  margin: 0px;
  margin-left: 10%;
  padding-right: 10%;
  text-align: center;
  background: #e5e5e5;
  font-weight: 500;
}
