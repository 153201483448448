.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 5%;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.callconnectingpopupinner {
  position: absolute;
  left: 36%;
  right: 36%;
  top: 20%;
  margin: auto;
  background: white;
}

.popup-call-icons {
  color: green !important;
  font-size: 50px !important;
  cursor: pointer;
}

.popup-end-icons {
  color: red !important;
  font-size: 50px !important;
  cursor: pointer;
}

.modal-footer {
  justify-content: center !important;
}
.call-image-view {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.call-image-style {
  width: 90px;
  height: 90px;
  border-radius: 90px;
  position: relative;
  border: 1px solid lightgray;
}
.call-name-view {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 15px;
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  outline: 0;
}
.modal-header {
  display: flex;
  justify-content: center;
}
.modal-title {
  text-align: center;
}

.call-history-modal-hight {
  height: 460px;
  overflow-y: auto;
}

@media screen and (max-width: 1600px) {
  .call-history-modal-hight {
    height: 360px;
  }
  .modal-font {
    font-size: 12px;
  }
}

@media screen and (max-width: 1300px) {
  .call-history-modal-hight {
    height: 280px;
  }
  .modal-font {
    font-size: 11px;
  }
}
