.login-page {
  background-image: url("../assets/background-image.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: inherit;
}
.login-view {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 86%;
  height: 90%;
}
.reset-card {
  min-width: 27%;
  position: relative;
  bottom: 15px;
  text-align: -webkit-right;
  text-align: -moz-right;
}
.reset-logo-view {
  display: flex;
  justify-content: flex-end !important;
  padding: 15px;
  margin-right: 50px;
  width: 60%;
}
.reset-card-view {
  background: #2c5566;
  border-radius: 62px;
  padding: 40px 45px 30px 40px;
  display: block;
  position: relative;
  width: 55%;
}

@media screen and (max-width: 800px) {
  .reset-logo-view {
    width: 50%;
  }
  .reset-card-view {
    width: 47%;
  }
}
.logo-image {
  width: 60%;
}
.card-header {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}
.card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-size: 15px;
  background: transparent;
  border-bottom: 1px;
}
.form-view {
  margin-left: 10px;
  margin-right: 10px;
}
.form-controls {
  margin-top: 10px;
  width: 100%;
}
.form-label {
  font-size: 14px !important;
  color: #fff !important;
}
.text-fields {
  width: 100%;
  border-bottom: 1px solid #fff;
  margin-bottom: 15px;
}
.err {
  color: red;
  text-align: start;
  padding: 10px;
}
.field-icon {
  color: #fff;
}
.button-view {
  display: flex;
  justify-content: center;
}
.button-style {
  padding: 12px !important;
}
.reset-button {
  background: #b2cdcf !important;
  border-radius: 8px;
  color: #fff !important;
  padding: 10px !important;
  font-weight: bold;
  padding-left: 35px !important;
  padding-right: 35px !important;
  cursor: pointer;
}
.field-icon {
  cursor: pointer;
}
